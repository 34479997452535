import axios from "axios";
import { useStore } from "./../constants/store/store";
import { getAuth } from "firebase/auth";

const API = axios.create({
    //baseURL: "https://gpter-ff5b485e1dad.herokuapp.com/", // REMEMBER to change back to heroku when deploying
    //baseURL: "http://localhost:5001/", // REMEMBER to change back to heroku
    baseURL: "https://gpter-ff5b485e1dad.herokuapp.com/",
});

//https://gpter-ff5b485e1dad.herokuapp.com/
// Apply the interceptor to update Authorization header before each request
API.interceptors.request.use((config) => {
    config.headers.Authorization = useStore.getState().token;
    return config;
});

export const APISendMessage = (message) =>
    API.post(`/chat/`, { user_input: message });
export const APIInitiateChat = (industry) =>
    API.get(`/initiate_chat`, { params: { industry: industry } });
export const APIInitiateVocabs = (industry, title) =>
    API.get(`/initiate_vocabs`, { params: { industry: industry, title: title } });
export const APIPersonalChat = (industry, term, definition) =>
    API.get(`/personalchat`, { params: { industry: industry, term: term, definition : definition} });
export const APIGetFeedback = (chatHistory) =>
    API.post(`/feedback/`, { chat_history: chatHistory });
export const APIFlashCardChat = () => API.get(`/flashcard_chat`);
export const APISuggest = (chatHistory) =>
    API.post(`/suggest/`, { chat_history: chatHistory});
export const APISearch = (message) =>
    API.post(`/news`, { user_input: message });


    