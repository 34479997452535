import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";  // Changed to signInWithPopup
import { auth } from "../index.js";
import { APIStoreUser } from "../actions/APIstoreChat";

async function GoogleLogin() {
    try {
        console.log("Starting Google login process...");
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        // Use signInWithPopup instead of redirect for easier debugging
        const result = await signInWithPopup(auth, provider);
        console.log("Google sign in result:", result);

        if (result.user) {
            console.log("Google login successful");
            await APIStoreUser();
            return result;
        }
    } catch (error) {
        console.error("Google login error:", {
            code: error.code,
            message: error.message,
            email: error.customData?.email,
        });
        throw error;
    }
}


async function signOut() {
    try {
        await auth.signOut();
        console.log("Sign out successful");
    } catch (error) {
        console.error("Sign out error:", error);
        throw error;
    }
}

export { GoogleLogin, signOut };
