import React, { useState, useEffect, useRef } from 'react';
import vocabData from '../../assets/vocabs/wordle.json';
import { useStore } from "../../constants/store/store";
import { Container, CssBaseline, Box, Grid, Button } from "@mui/material";
import OptionDialog from "../../components/SetOptionsUI/DifficultyDialog.js";

const styles = {
  wordle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wordleGrid: {
    display: 'grid',
    gridGap: '5px',
  },
  wordleCell: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    border: '2px solid #ccc',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  correct: {
    backgroundColor: 'green',
    color: '#fff',
  },
  present: {
    backgroundColor: 'yellow',
  },
  absent: {
    backgroundColor: '#ccc',
  },
  keyboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  keyboardRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  key: {
    width: '40px',
    height: '50px',
    margin: '0 2px',
    fontSize: '1rem',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '4px',
    background: '#d3d6da',
    cursor: 'pointer',
  },
};

const Keyboard = ({ usedLetters, onKeyPress }) => {
  const getKeyStyle = (key) => {
    const baseStyle = {
      ...styles.key,
      width: key === 'ENTER' || key === '⌫' ? '65px' : '40px',
      backgroundColor: '#d3d6da', // Ensure default background is always set
      color: 'black', // Ensure default text color is always set
    };

    if (!usedLetters || !usedLetters[key]) return baseStyle;

    const colorStyles = {
      correct: { backgroundColor: 'green', color: 'white' },
      present: { backgroundColor: 'yellow', color: 'black' },
      absent: { backgroundColor: '#ccc', color: 'white' }
    };

    return {
      ...baseStyle,
      ...(colorStyles[usedLetters[key]] || {})
    };
  };
  const rows = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫']
  ];

  const handleKeyClick = (key) => {
    onKeyPress(key);
  };

  return (
    <div style={styles.keyboard}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={styles.keyboardRow}>
          {row.map((key) => (
            <button
              key={key}
              onClick={() => onKeyPress(key)}
              style={getKeyStyle(key)}
            >
              {key}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

const WordlePractice = () => {
  const [word, setWord] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [definition, setDefinition] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gridSize, setGridSize] = useState(6);
  const [difficulty, setDifficulty] = useState('easy');
  const [difficultyChanged, setDifficultyChanged] = useState(false);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const [usedLetters, setUsedLetters] = useState({});
  const buttonRef = useRef(null);

  const [chosenIndustry, setChosenIndustry] = useState(
    useStore((state) => state.industry)
  );

  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
    setDifficultyChanged(true);
    setShowDifficultyModal(false);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    window.blur();
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    window.blur();
  }, []);

  useEffect(() => {
    const selectedWord = getRandomWord(chosenIndustry, difficulty);
    if (selectedWord) {
      const { word, definition: def } = selectedWord;
      setWord(word);
      setDefinition(def);
      setGridSize(Math.max(6, word.length));
      setDifficultyChanged(false);
      // Explicitly reset used letters to an empty object
      setUsedLetters({});
      // Reset other game states
      setGuesses([]);
      setCurrentGuess('');
      setCurrentRow(0);
      setGameOver(false);
    } else {
      alert('No words found for the selected difficulty level.');
    }
  }, [difficulty, chosenIndustry, difficultyChanged]);

  const handleKeyPress = async (key) => {
    if (gameOver) return;

    if (key === 'ENTER') {
      if (currentGuess.length === word.length) {
        buttonRef.current.blur();
        const isValidWord = await checkWordValidity(currentGuess);
        if (!isValidWord) {
          alert('Not a valid word!');
          return;
        }

        setGuesses([...guesses, currentGuess]);
        setCurrentGuess('');
        setCurrentRow(currentRow + 1);

        // Update keyboard colors using functional update
        setUsedLetters((prevUsedLetters) => {
          const newUsedLetters = { ...prevUsedLetters };
          const guessArray = currentGuess.split('');
          const wordArray = word.split('');
          
          // First pass: mark correct letters
          guessArray.forEach((letter, i) => {
            if (letter === wordArray[i]) {
              newUsedLetters[letter] = 'correct';
            }
          });
          
          // Second pass: mark present/absent letters
          guessArray.forEach((letter, i) => {
            if (!newUsedLetters[letter] || newUsedLetters[letter] !== 'correct') {
              if (wordArray.includes(letter)) {
                newUsedLetters[letter] = 'present';
              } else {
                newUsedLetters[letter] = 'absent';
              }
            }
          });
          
          return newUsedLetters;
        });

        if (currentGuess === word) {
          setGameOver(true);
        } else if (currentRow === gridSize - 1) {
          setGameOver(true);
        }
      }
    } else if (key === '⌫') {
      setCurrentGuess(currentGuess.slice(0, -1));
    } else if (currentGuess.length < word.length) {
      setCurrentGuess(currentGuess + key);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleKeyPress('ENTER');
    } else if (event.key === 'Backspace') {
      handleKeyPress('⌫');
    } else if (event.key.match(/^[a-zA-Z]$/)) {
      handleKeyPress(event.key.toUpperCase());
    }
  };

  const handleKeyboardInput = (event) => {
    if (gameOver) return;
    handleKeyDown(event);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardInput);
    return () => {
      window.removeEventListener('keydown', handleKeyboardInput);
    };
  }, [currentGuess, word, currentRow, gameOver]);

  const checkWordValidity = async (guess) => {
    const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${guess.toLowerCase()}`);
    const data = await response.json();
    return data.title !== 'No Definitions Found';
  };

  useEffect(() => {
    if (difficultyChanged) {
      setGuesses([]);
      setCurrentGuess('');
      setCurrentRow(0);
      setGameOver(false);
      setUsedLetters({});
    }
  }, [difficultyChanged]);

  const getGridSize = () => {
    return {
      gridTemplateColumns: `repeat(${word.length}, 1fr)`,
      gridTemplateRows: `repeat(${gridSize}, 1fr)`,
    };
  };

  const getLetterStatus = (letter, index, rowIndex) => {
    if (!letter || rowIndex >= guesses.length) return '';

    const guess = guesses[rowIndex];
    const wordArray = word.split('');

    // If the letter is in the correct position
    if (letter === wordArray[index]) {
      return 'correct';
    }

    // If the letter exists in the word but in wrong position
    if (wordArray.includes(letter)) {
      // Count how many times the letter appears in the word
      const letterCount = wordArray.filter(l => l === letter).length;
      // Count how many times we've already found this letter in correct positions
      const correctPositions = guess.split('').filter((l, i) => l === letter && wordArray[i] === letter).length;
      
      if (correctPositions < letterCount) {
        return 'present';
      }
    }

    return 'absent';
  };

  const getRandomWord = (category, difficulty) => {
    const categoryData = vocabData[category];
    if (!categoryData) {
      return null;
    }

    const difficultyData = categoryData.find(item => item[difficulty]);
    if (!difficultyData) {
      return null;
    }

    const wordObjects = difficultyData[difficulty];
    if (wordObjects.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * wordObjects.length);
    const randomWordObject = wordObjects[randomIndex];
    const word = Object.keys(randomWordObject)[0];
    const definition = Object.values(randomWordObject)[0];
    return { word: word.toUpperCase(), definition };
  };

  return (
    <div style={styles.wordle}>
      <Button
        ref={buttonRef}
        tabIndex={-1}
        sx={{
          margin: "20px",
          backgroundColor: "#509458",
          color: "#FFFFFF",
          padding: "0.75rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EFFFEF",
            color: "#509458",
          },
          minWidth: "7rem",
          borderRadius: "20px",
        }}
        onClick={() => setShowDifficultyModal(true)}
      >
        {difficulty.toUpperCase()}
      </Button>
      {showDifficultyModal && (
        <OptionDialog
          open={showDifficultyModal}
          setOpen={setShowDifficultyModal}
          action={handleDifficultyChange}
          options={["Easy", "Medium", "Hard"]}
          buttonRef={buttonRef}
        />
      )}
      <div style={{ ...styles.wordleGrid, ...getGridSize() }}>
        {Array(gridSize)
          .fill()
          .map((_, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {Array(word.length)
                .fill()
                .map((_, cellIndex) => {
                  const letter =
                    rowIndex < guesses.length
                      ? guesses[rowIndex][cellIndex] || ''
                      : rowIndex === currentRow
                      ? currentGuess[cellIndex] || ''
                      : '';

                  return (
                    <div
                      key={cellIndex}
                      style={{
                        ...styles.wordleCell,
                        ...(rowIndex < guesses.length ? styles[getLetterStatus(letter, cellIndex, rowIndex)] || {} : {}),
                      }}
                    >
                      {letter}
                    </div>
                  );
                })}
            </React.Fragment>
          ))}
      </div>
      {gameOver && (
        <div>
          {currentRow === gridSize ? (
            <>
              <p>The word was: {word}</p>
              <p>Definition: {definition}</p>
            </>
          ) : (
            <>
              You got the word!
              <p>Definition: {definition}</p>
            </>
          )}
        </div>
      )}
      <Keyboard usedLetters={usedLetters} onKeyPress={handleKeyPress} />
    </div>
  );
};

export default WordlePractice;