// Login.js
import React, { useEffect, useState } from "react";
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signInWithRedirect,
    getRedirectResult,
} from "firebase/auth";
import { auth } from "../../index.js"
import { useStore } from "../../constants/store/store";
import { GoogleLogin } from "../../constants/GoogleAuth";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    CssBaseline,
    Link,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
    IconButton,
    Avatar,
} from "@mui/material";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "./../../assets/google-icon.png";
import LoadingOverlay from "../../components/misc/loading";
import { APIStoreUser } from "../../actions/APIstoreChat";
import Message from "../../components/chat/message";

const Login = () => {
    console.log("Login component rendering");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { setIsAuthorized, setToken } = useStore();
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        console.log("Setting up auth state monitor");
        // Log initial auth state
        console.log("Initial auth state:", auth.currentUser);

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log("Auth state changed, user:", user);
            if (user) {
                try {
                    const token = await user.getIdToken();
                    console.log("Token obtained:", token ? "Yes" : "No");
                    setToken(token);
                    setIsAuthorized(true);
                    console.log("Navigating to home...");
                    navigate("/home");
                } catch (error) {
                    console.error("Token error:", error);
                }
            } else {
                console.log("No user found in auth state change");
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [navigate, setToken, setIsAuthorized]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("=== Login Process Starting ===");
        console.log("Email:", email);
        console.log("Password length:", password?.length);
    
        if (!email || !password) {
            console.log("Missing credentials");
            setError("Login error: All fields are required");
            return;
        }
    
        try {
            setLoading(true);
            console.log("Attempting Firebase sign in...");
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log("Firebase Response:", userCredential);
            
            if (userCredential.user) {
                console.log("User object received:", userCredential.user.email);
                const token = await userCredential.user.getIdToken();
                console.log("Token received");
                setToken(token);
                setIsAuthorized(true);
                console.log("Navigating to home");
                navigate("/home");
            }
        } catch (error) {
            console.error("AUTH ERROR:", {
                code: error.code,
                message: error.message,
                fullError: error
            });
            setLoading(false);
            setError(`Login failed: Invalid email or password`);
        }
    };

    const useGoogleLogin = async () => {
        try {
            console.log("Starting Google login...");
            setLoading(true);
            const result = await GoogleLogin();
            
            if (result?.user) {
                const token = await result.user.getIdToken();
                setToken(token);
                setIsAuthorized(true);
                navigate("/home");
            }
        } catch (error) {
            console.error("Google login failed:", error);
            setError("Google login failed: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={styles}>
            {loading ? <LoadingOverlay /> : <></>}
            <Container
                component="main"
                style={{
                    padding: 0,
                    height: "100%",
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "100%",
                }}
            >
                <CssBaseline />
                <AppBar
                    position="relative"
                    style={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                marginTop: "-10px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                src={BizLangIcon}
                                height={75}
                                width={100}
                            />
                        </Typography>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "0",
                                padding: "0",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.6rem",
                                fontFamily: "monospace",
                                paddingTop: "15px",
                            }}
                        >
                            BIZLANG
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container
                    component="div"
                    maxWidth="xs"
                    style={{ flexGrow: 1 }}
                >
                    <CssBaseline />

                    <Box
    sx={{
        marginTop: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }}
>
    <Typography component="h1" variant="h4">
        Sign in to practice your industry's lingo
    </Typography>
    <Box 
        component="form" 
        noValidate 
        sx={{ mt: 1 }} 
        onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
        }}
    >
        <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={(e) => {
                setEmail(e.target.value);
                setError("");
            }}
        />
        <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => {
                setPassword(e.target.value);
                setError("");
            }}
        />
        {!(error === "") ? (
            <Typography
                style={{
                    color: "red",
                    fontWeight: "bold",
                    margin: 0,
                }}
            >
                {error}
            </Typography>
        ) : (
            <></>
        )}
        <Grid container>
            <Grid
                item
                xs
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Link
                    variant="body2"
                    onClick={() => {
                        navigate("/forgotPassword");
                    }}
                >
                    Forgot password?
                </Link>
            </Grid>
        </Grid>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => console.log("Button clicked")}  // Add this temporarily

        >
            Sign In
        </Button>
        <Typography
            style={{
                color: "black",
                fontWeight: "normal",
                margin: 0,
            }}
        >
            or
        </Typography>
        <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, padding: 0 }}
            style={{
                backgroundColor: "#649eff",
                justifyContent: "center",
            }}
            onClick={useGoogleLogin}
        >
            <IconButton
                style={{
                    padding: 0,
                    margin: 0,
                }}
            >
                <Avatar
                    src={GoogleIcon}
                    alt="Custom Icon"
                    style={{
                        height: "2.5rem",
                        width: "2.5rem",
                        margin: 0,
                        marginRight: 10,
                        backgroundColor: "white",
                        padding: 8,
                    }}
                />
            </IconButton>
            Sign in with Google
        </Button>
        <Grid item>
            <Link
                variant="body1"
                onClick={() => {
                    navigate("/signup");
                }}
            >
                {"Don't have an account? Sign Up!"}
            </Link>
        </Grid>
    </Box>
</Box>
</Container>
<Box
    sx={{
        flexGrow: 0,
        justifyContent: "center",
        display: "flex",
        backgroundColor: "green",
    }}
>
    <Toolbar
        style={{
            backgroundColor: "green",
            alignContent: "center",
            justifyContent: "center",
        }}
    >
        <Typography variant="caption" color="white">
            Copyright ©2025 BIZLANG. All Rights Reserved
        </Typography>
    </Toolbar>
</Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;